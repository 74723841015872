<template>
  <div v-if="dataReady">
    <h5 style="margin-top:0.5rem">{{column.label}}</h5>
    <multiselect
      @select="addItem"
      @remove="removeItem"
      v-model="options.selected"
      tag-placeholder="Add this as new tag"
      placeholder="Search or add a tag"
      label="value"
      :show-labels="false"
      track-by="value"
      open-direction="below"
      :options="options.available"
      :multiple="true"
      :taggable="true"
      @tag="addTag"
      ></multiselect>
  </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    column : {type: Object, required: true},
    dataSource: {type: String, required: true},
    dateSorter: {type: String, required: false},
    mode: {type: String, required: true},
    userId: {type: Number, required: false}
  },
  data() {
    return {
      dataReady : false,
      options : {
        available: null,
        selected: null
      }
    };
  },
  mounted() {
    this.processData();
    if(this.mode == 'global' || this.mode == 'tag'){
      EventBus.$on('globalFilterChange', (message) => {
       if(message[0] == 'clear' || message[1] == this.column.name){
        this.processData();
       }
      });
    }
    if(this.mode == 'session'){
      EventBus.$on('sessionFilterChange', (message) => {
       if(message[0] == 'clear' || message[1] == this.column.name){
        this.processData();
       }
      });
    }
  },

  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag);
      this.value.push(tag);
    },

    async getData(){
      try{
        return await axios.get('/getSelectionOptions',{params :
                                                        { column: this.column.name,
                                                          datasource: this.dataSource,
                                                          datesorter: this.dateSorter,
                                                          mode: this.mode,
                                                          userId: this.userId
                                                        }
                                                      });
      }
      catch (error) {
        console.log(error);
      }
    },

    processData(){
      this.getData().then(response => {
        this.options.available = JSON.parse(response.data).available;
        this.options.selected = JSON.parse(response.data).selected;
        this.dataReady = true;
      })
      .catch(error => {
        console.log(error);
      });
    },

    addItem(payload){

        var POSTbody = {
            dataItem: this.column.name,
            dataValue: payload.value,
            mode: this.mode,
            userId: this.userId
        }
        axios({
            method: 'post',
            url: '/filter/add',
            data: POSTbody
        })
        .then(function (response) {
            //Sending refresh signal to other objects
            if(this.mode == 'global' || this.mode == 'tag'){
              EventBus.$emit('globalFilterChange', ['add',this.column.name]);
            }
            if(this.mode == 'session'){
              EventBus.$emit('sessionFilterChange', ['add',this.column.name]);
            }
        }.bind(this))
        .catch(function (error) {
            console.log(error);
        });

    },

    removeItem(payload){

        var POSTbody = {
          dataItem: this.column.name,
          dataValue: payload.value,
          mode: this.mode,
          userId: this.userId
        }
        axios({
            method: 'post',
            url: '/filter/remove',
            data: POSTbody
        })
        .then(function (response) {
          if(this.mode == 'global' || this.mode == 'tag'){
            //Sending refresh signal to other objects
            EventBus.$emit('globalFilterChange', ['remove', this.column.name]);
          }
          if(this.mode == 'session'){
            //Sending refresh signal to other objects
            EventBus.$emit('sessionFilterChange', ['remove', this.column.name]);
          }
        }.bind(this))
        .catch(function (error) {
            console.log(error);
        });

    }
  }
};
</script>
