<template>
  <div>
    <div class="card shadow mb-4">

      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Completion Statistics</h6><small>(<sup>*</sup>Only fully signed off tasks are included)</small>
        <div>
          Split by:
          <select v-model="selectedDimension" v-on:change="processData">
            <option disabled>-- Category --</option>
            <option id="category1" value="category1">Category 1</option>
            <option id="category2" value="category2">Category 2</option>
            <option disabled>-- Location --</option>
            <option id="region" value="region">Region</option>
            <option id="site" value="site">Site</option>
            <option id="location1" value="location1">Location 1</option>
            <option id="location2" value="location2">Location 2</option>
            <option disabled>-- Contractor --</option>
            <option id="contractor_name" value="contractor_name">Contractor Name</option>
            <option disabled>-- Priority --</option>
            <option id="priority" value="priority">Priority</option>
          </select>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{this.displayDimension}}</th>
                <th>Completion %<sup>*</sup></th>
                <th>Completed<sup>*</sup></th>
                <th>With Contractor</th>
                <th>Pending Allocation</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-success">
                <td style="color:white"><b>Overall</b></td>
                <td style="width:150px">
                  <div class="progress" style="height:30px">
                    <div class="progress-bar" role="progressbar" v-bind:style="{width: percentage(this.sumCompleted,this.sumPending,this.sumAllocated) + '%'}"><span style="color:white"><b>{{percentage(this.sumCompleted,this.sumPending,this.sumAllocated)}}%</b></span></div>
                  </div>
                </td>
                <td style="color:white"><b>{{this.sumCompleted}}</b></td>
                <td style="color:white"><b>{{this.sumAllocated}}</b></td>
                <td style="color:white"><b>{{this.sumPending}}</b></td>
              </tr>
              <tr v-for="row in datasets">
                <td>{{row.dimension}}</td>
                <td style="width:150px">
                  <div class="progress" style="height:30px">
                    <div class="progress-bar" role="progressbar" v-bind:style="{width: percentage(row.completed,row.pending,row.allocated) + '%'}"><span style="color:white"><b>{{percentage(row.completed,row.pending,row.allocated)}}%</b></span></div>
                  </div>
                </td>
                <td>{{row.completed}}</td>
                <td>{{row.allocated}}</td>
                <td>{{row.pending}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';

export default {

  props: {
    dataSource: null
  },

  data () {
    return {
      datasets: null,
      dataready: false,
      selectedDimension: 'category1'
    }
  },
  computed: {
    displayDimension(){
       if(this.dataready){
         return document.getElementById(this.selectedDimension).text;
       }
       else{
         return "Waiting..."
       }
    },

    sumCompleted(){
      if(this.dataready){
        var total = this.datasets.reduce(function(prev, cur) {
          return prev + cur.completed;
        }, 0);
        return total;
      }
      else{
        return 'Waiting...';
      }
    },

    sumAllocated(){
      if(this.dataready){
        var total = this.datasets.reduce(function(prev, cur) {
          return prev + cur.allocated;
        }, 0);
        return total;
      }
      else{
        return 'Waiting...';
      }
    },

    sumPending(){
      if(this.dataready){
        var total = this.datasets.reduce(function(prev, cur) {
          return prev + cur.pending;
        }, 0);
        return total;
      }
      else{
        return 'Waiting...';
      }
    }
  },

  methods: {
    percentage(completed,pending,allocated){
      return Math.round((completed/(completed+pending+allocated))*100) || 0;
    },



    async getData(
            dataSource,
            dimensionField
            ){
      try{
        return await axios.get('/getComplianceData',{
          params: {
            dataSource: dataSource,
            dimensionField: dimensionField
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },

    processData(){
      console.log('Requesting data...');
      this.getData(
        this.dataSource,
        this.selectedDimension
      ).then(response => {
        console.log('Response received...');

        this.datasets = response.data;

        this.dataready = true;

      })
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },

  mounted () {
    this.processData();
    console.log(document.getElementById("category1").text);
  }
}
</script>
