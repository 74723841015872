<template>
  <div>
    <a href="#" @click.prevent="filterReset" class="collapse-item"><i class="fas fa-trash-alt"></i> Clear All</a>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
export default {
  methods: {
    async filterReset(){
      await axios.get('/filter/clear?mode=global');
      await axios.get('/filter/clear?mode=tag');
      await EventBus.$emit('globalFilterChange', ['clear','all']);
    }
  }
}
</script>
