<template>
  <div>
    <label>Search:</label>
    <input class="form-control" v-model="filters.all.value"/>

    <v-table class="table table-sm table-striped table-hover table-condensed"
      v-if="dataReady"
      :data="realData"
      :currentPage.sync="currentPage"
      :pageSize="displayLimit"
      @totalPagesChanged="totalPages = $event"
      :filters="filters"
    >
      <thead slot="head">
        <v-th v-for="(obj,key,index) in dataLinkFieldHidden ? dataColumns['columns'].slice(1,dataColumns['columns'].length) : dataColumns['columns']" v-bind:obj="obj" v-bind:key="index" :sortKey="obj" style="white-space: nowrap">
          {{ dataLinkFieldHidden ? dataColumns['labels'].slice(1,dataColumns['labels'].length)[key] : dataColumns['labels'][key] }}
        </v-th>
      </thead>
      <tbody slot="body" slot-scope="{displayData}">
        <tr v-for="row in displayData" :key="row.id">
          <td v-for="(obj,index) in dataLinkFieldHidden ? Object.values(row).slice(1,Object.values(row).length) : Object.values(row)">
            <span v-html="displayValue(obj,index,row)"></span>
          </td>
        </tr>
      </tbody>
    </v-table>
    <smart-pagination
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :maxPageLinks="10"
        :boundaryLinks="true"
      />
  </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    props:{
      dataSource: {type: String, default: ""},
      dataColumns: {type: Object},
      dataFilter: {type: Object},
      dataSortby: {type: String, default: ""},
      dataLimit: {type: Number, default: null},
      displayLimit: {type: Number, default: 25},
      maintenoLinkField: {type: String, default: ""},
      maintenoAccountPath: {type: String, default: ""},
      maintenoLinkType: {type: String, default: "faults"},

      dataLinkEnabled: {type: Boolean, default: false},
      dataLinkPath: {type: String, default: null},
      dataLinkEntity: {type: String, default: null},
      dataLinkField: {type: String, default: null},
      dataLinkFieldHidden: {type: Boolean, default: false},
      dataLinkAnchorField: {type: String, default: null}
    },
    data() {
      return {
        detailData: {type: Array, default: [{}]},
        dataLinkKeys: [],
        dataLinkFieldIndex: null,
        maintenoLinkFieldIndex: null,
        dataReady: {type: Boolean, default: false},
        currentPage: 1,
        totalPages: 0,
        filters: {
          all: { value: '', keys: this.dataColumns['columns'] }
        }
      }
    },

    computed: {
      realData: function(){
       if(this.dataReady == true){
        return this.detailData;
       }
       else{
        var dummyData = [{}];
        return dummyData;
       }
      }
    },

    mounted() {

      //get ID of dataLinkAnchorField

      if(this.dataLinkEnabled){
        this.dataColumns['columns'].unshift(this.dataLinkField);
        this.dataColumns['labels'].unshift(this.dataLinkField);
      }

      if(this.dataLinkFieldHidden){
          this.dataLinkFieldIndex = this.dataColumns['columns'].slice(1,this.dataColumns['columns'].length).indexOf(this.dataLinkAnchorField)
      }
      else{
        this.dataLinkFieldIndex = this.dataColumns['columns'].indexOf(this.dataLinkAnchorField)
      }

      //Cater for old until all props are update to use new dataLink... props for linking

      if(this.maintenoLinkField != ''){
        this.maintenoLinkFieldIndex = this.dataColumns['columns'].indexOf(this.maintenoLinkField);
      }

      this.processData();

      EventBus.$on('sessionFilterChange', (message) => {
        this.processData();
      });

      EventBus.$on('globalFilterChange', (message) => {
        this.processData();
      });
    },

    methods:{

      displayValue(obj,index,row){
        // Cater for old until tidy up!
        if(this.maintenoLinkField != ''){
          if(index == this.maintenoLinkFieldIndex){
            return "<a target='_blank' href='https://www.mainteno.co.uk/" + this.maintenoAccountPath + "/" + this.maintenoLinkType + "/" + obj + "'>" + obj + '</a>';
          }
          else{
            return obj;
          }
        }
        else{
          if(this.dataLinkEnabled && (index == this.dataLinkFieldIndex)){
            return "<a target='_blank' href='https://www.mainteno.co.uk/" + this.dataLinkPath + "/" + this.dataLinkEntity + "/" + Object.values(row)[index] + "'>" + obj + '</a>';
          }
          else{
            return obj;
          }


          // if(this.dataLinkEnabled && ((this.dataLinkAnchorField == key) || (this.dataLinkAnchorField == null && index == 0))){
          //   return "<a target='_blank' href='https://www.mainteno.co.uk/" + this.dataLinkPath + "/" + this.dataLinkEntity + "/" + this.dataLinkKeys[rowId] + "'>" + value + '</a>';
          // }
          // else{
          //   return value;
          // }
        }
      },

      // makeLink(obj,key){
      //   if(key == this.maintenoLinkField){
      //     return "<a target='_blank' href='https://www.mainteno.co.uk/" + this.maintenoAccountPath + "/" + this.maintenoLinkType + "/" + obj + "'>" + obj + '</a>';
      //   }
      //   else{
      //     return obj;
      //   }
      // },

      async getDetailData(
              dataSource,
              dataColumns,
              dataFilter,
              dataSortby,
              dataLimit){
        try{
          return await axios.get('/getDetailData',{
            params: {
              dataSource: dataSource,
              dataFilter: dataFilter,
              dataColumns: dataColumns,
              dataSortby: dataSortby,
              dataLimit: dataLimit
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.getDetailData(
          this.dataSource,
          this.dataColumns,
          this.dataFilter,
          this.dataSortby,
          this.dataLimit
        ).then(response => {

          //Set response to data and labels
          this.detailData = JSON.parse(response.data);

          if(this.dataLinkEnabled){
            this.detailData.forEach(function(row){
              this.dataLinkKeys.push(row[this.dataLinkField]);
            }.bind(this));
          }

          //Set render flag
          this.dataReady = true;

        })
        .catch(error => {
          console.log(error);
          this.dataReady = false;
        });
      }
    }
  }
</script>
