<template>
  <div>
    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->

      <!-- Card Body -->
      <div class="card-body">
        <component
          v-bind:is="chartType"
          :chart-datasets="datasets"
          :chart-labels="labels"
          v-if="dataready"
          >
        </component>

      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
import DoughnutChart from '../charts/vue-chartjs-doughnut-responsive.js'
import BarChart from '../charts/vue-chartjs-bar-responsive.js'
import HorizontalBarChart from '../charts/vue-chartjs-bar-horizontal-responsive.js'
import LineChart from '../charts/vue-chartjs-line-responsive.js'
import PieChart from '../charts/vue-chartjs-pie-responsive.js'


export default {
  components: {
    DoughnutChart,
    BarChart,
    PieChart,
    LineChart,
    HorizontalBarChart
  },

  props: {
    liveDataSet: null,
    chartType : {type: String, required: true},
    measureName : null
  },

  data () {
    return {
      datasets: null,
      labels: null,
      dataready: false
    }
  },

  methods: {

    async getData(liveDataSet){
      try{
        return await axios.get('/getLiveData',{
          params: {
            liveDataSet: liveDataSet
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },
    processData(){
      console.log('Requesting data...');
      this.getData(this.liveDataSet).then(response => {
        console.log('Response received...');

        //Set response to data and labels
        this.labels = response.data.map(dimension => dimension.dimension);

        this.tmpData = response.data.map(measure => measure.measure);

        if(this.tmpData.toString() != this.datasets.data.toString()){
          this.datasets.data = response.data.map(measure => measure.measure);
          this.dataready = true;
        }
      })
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },

  mounted () {

    EventBus.$on('liveUpdate', (message) => {
      this.processData();
    });

    this.labels = ['No Data'];

    //Set defaults
    this.datasets =
      {
        label: this.measureName,
        data: [0]
      }
    ;

    //Make initial data Request
    console.log('Initial data request...');
    this.processData();
  }
}
</script>
