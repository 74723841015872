<template>
<div>
  <div v-for="(value,key,index) in currentSelections" class="mb-4">
    <h5>{{toProperCase(key.replace(/_/g,' '))}}</h5>
    <div v-for="filter in value">
      <a v-on:click="removeSelection(key,filter)" class="btn btn-primary" style="margin-top:5px;color:white;">
        {{filter}} <i class="fas fa-fw fa-times-circle"></i>
      </a>
    </div>
  </div>
</div>

</template>
<script>
  import { EventBus } from '../../event-bus.js';
    export default {
        data() {
          return {
            currentSelections: null
          }
        },

        mounted() {
            console.log('Selections Component mounted.');
            this.currentSelections = null
            this.processData();
            EventBus.$on('sessionFilterChange', (message) => {
                this.processData();
            });

        },

        methods: {
          async getData(){
            try{
              return await axios.get('/filter/get?mode=session')
            }
            catch (error) {
              console.log(error);
            }
          },
          processData(){
            console.log('Requesting current selctions...');
            this.getData().then(response => {
              console.log('Response received...');

              //Set response to data and labels
              console.log(response.data);
              this.currentSelections = response.data;
            })
            .catch(error => {
              console.log(error);
            });
          },
          toProperCase(s)
          {
            return s.toLowerCase().replace(/^(.)|\s(.)/g,
                    function($1) { return $1.toUpperCase(); });
          },
          removeSelection(dataItem,dataValue){
            var POSTbody = {
                dataItem: dataItem,
                dataValue: dataValue,
                mode: 'session'
            }
            axios({
                method: 'post',
                url: '/filter/remove',
                data: POSTbody
            })
            .then(function (response) {
                //console.log(response);
                console.log('Emitting - filter removed for ' + dataItem);
                //Sending refresh signal to other objects
                EventBus.$emit('sessionFilterChange', ['remove', dataItem]);
            })
            .catch(function (error) {
                console.log(error);
            });
          }
        }
      }
  </script>
