<template>
<div class="mb-4">

  <div class="shadow h-100 py-2">

      <div class="row text-center">
        <div class="col text-xl" :class="ragColor ? ragColor : textColor">
          <a v-on:click="detailEnable ? modalGo(modalHashId) : false;">
            <div v-if="kpiReady" :class="kpiSize">{{animatedNumber}}%</div>
          </a>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <div v-bind:class="labelClass" class="text-xs font-weight-bold text-uppercase mb-1">
            {{label}}
            <i v-if="tooltipConfig" data-toggle="tooltip" data-trigger="click focus hover" :title="tooltipConfig.text" class="far fa-question-circle text-gray-600 pr-2" style="float:right"></i>
          </div>
        </div>
      </div>

  </div>


    <!-- Modal -->
  <div v-if="detailEnable" class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="detailModal" aria-hidden="true">
    <div class="modal-dialog" v-bind:class="detailModalSize" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="detailModal">{{detailLabel}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body overflow-auto">
                  <div v-if="getDetail">
                    <detail-table
                      :data-source="detailSource"
                      :data-filter="detailDataFilter"
                      :data-columns="detailColumns"
                      :data-sortby="detailSortby"
                      :data-limit="detailDataLimit"

                      :data-link-enabled="detailLinkEnabled"
                      :data-link-path="detailLinkPath"
                      :data-link-entity="detailLinkEntity"
                      :data-link-field="detailLinkField"
                      :data-link-field-hidden="detailLinkFieldHidden"
                      :data-link-anchor-field="detailLinkAnchorField"
                    ></detail-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div style="float:right;margin-bottom:10px" v-if="detailAllowExport">
            <a class="btn btn-primary" :href="fullExportRoute">Export</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

</template>
<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    props: {
      topSource: {type: String, default: null},
      topField: {type: String, default: null},
      topFunction: {type: String, default: null},
      topGroupFunction: {type: String, default: null},
      topGroupDimension: {type: String, default: null},
      topDataFilter: {type: Object},

      bottomSource: {type: String, default: null},
      bottomField: {type: String, default: null},
      bottomFunction: {type: String, default: null},
      bottomGroupFunction: {type: String, default: null},
      bottomGroupDimension: {type: String, default: null},
      bottomDataFilter: {type: Object},

      detailEnable: {type: Boolean, default: false},
      detailSource: {type: String, default: null},
      detailColumns: {type: Object, default: null},
      detailDataFilter: {type: Object, default: null},
      detailModalSize: {type: String, default: 'modal-lg'},
      detailAllowExport: {type: Boolean, default: false},
      detailSortby: {type: String, default: null},
      detailDataLimit: {type: Number, default: null},
      detailExportRoute: {type: String, default: null},
      detailLabel: {type: String, default: null},

      detailLinkEnabled: {type: Boolean, default: false},
      detailLinkPath: {type: String, default: null},
      detailLinkEntity: {type: String, default: null},
      detailLinkField: {type: String, default: null},
      detailLinkFieldHidden: {type: Boolean, default: false},
      detailLinkAnchorField: {type: String, default: null},

      label: '',
      labelClass: {type: String, default: 'text-primary'},
      icon: '',

      textSize: {type: Number, default: 4},
      textColor: {type: String, default: 'text-primary'},

      ragConfig: {type: Object, default: null},

      dataBinding: {type: String, default: 'dynamic'},

      tooltipConfig: {type: Object, default: null},

    },

    data () {
      return {
        topResult: 0,
        topReady: false,

        bottomResult: 0,
        bottomReady: false,

        kpiResultTween: 0,

        tweenedNumber: 0,

        modalId: null,
        modalHashId: null,

        getDetail: false
      }
    },

    computed: {

      fullExportRoute: function(){
        if(this.topDataFilter){
          return this.detailExportRoute + '?dataColumns=' + JSON.stringify(this.detailColumns.columns) + '&dataFilter=' + JSON.stringify(this.detailDataFilter);
        }
        else{
          return this.detailExportRoute + '?dataColumns=' + JSON.stringify(this.detailColumns.columns) + '&dataFilter=all';
        }

      },

      kpiSize: function(){
        return 'display-' + this.textSize;
      },

      ragColor: function(){
        if(this.ragConfig != null){
          if(this.ragConfig.direction == 'up'){
            if(this.kpiResult > this.ragConfig.thresholds[1]){
              return 'text-success';
            }
            else{
              if(this.kpiResult > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-danger'
              }
            }
          }
          else{
            if(this.kpiResult > this.ragConfig.thresholds[1]){
              return 'text-danger';
            }
            else{
              if(this.kpiResult > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-success'
              }
            }
          }

        }
      },


      kpiReady: function(){
        return this.topReady && this.bottomReady;
      },

      animatedNumber: function() {
        if(isNaN(this.$data.tweenedNumber)){
          //console.log(this.$data.tweenedNumber);
          return this.kpiResult;
        }
        else{
          return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },

      kpiResult: function(){
        return Math.round((this.topResult / this.bottomResult)*100,2);
      }
    },

    watch: {
      kpiResult: function(newValue) {
        TweenLite.to(this.$data, 0.5, { tweenedNumber: newValue });
      }
    },

    mounted() {
      this.processData();

      this.modalHashId = '#modal_' + this._uid;
      this.modalId = 'modal_' + this._uid;

      if(this.dataBinding == 'dynamic'){
        EventBus.$on('sessionFilterChange', (message) => {
          this.getDetail = false;
          this.processData();
        });

        EventBus.$on('globalFilterChange', (message) => {
          this.getDetail = false;
          this.processData();
        });
      }
    },

    methods:{

      modalGo(modalHashId){
        console.log('modal click' + modalHashId);
        this.getDetail = true;
        window.jQuery(modalHashId).modal();
      },

      async getData(
              source,
              field,
              kpiFunction,
              groupFunction,
              groupDimension,
              dataFilter){
        try{
          return await axios.get('/getKPI',{
            params: {
              kpiSource: source,
              kpiField: field,
              kpiFunction: kpiFunction,
              kpiGroupFunction : groupFunction,
              kpiGroupDimension : groupDimension,
              dataFilter: dataFilter
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.getData(
          this.topSource,
          this.topField,
          this.topFunction,
          this.topGroupFunction,
          this.topGroupDimension,
          this.topDataFilter
        ).then(response => {
          //Set response to data and labels
          this.topResult = response.data[0].measure;

          //Set render flag
          this.topReady = true;
        })
        .catch(error => {
          console.log(error);
          this.topReady = false;
        });

        this.getData(
          this.bottomSource,
          this.bottomField,
          this.bottomFunction,
          this.bottomGroupFunction,
          this.bottomGroupDimension,
          this.bottomDataFilter
        ).then(response => {
          //Set response to data and labels
          this.bottomResult = response.data[0].measure;

          //Set render flag
          this.bottomReady = true;
        })
        .catch(error => {
          console.log(error);
          this.bottomReady = false;
        });
      }

      //End KPI Data Stuff
    }
  }
</script>
