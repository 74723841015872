<template>
  <div v-if="dataReady">
    <select v-model="selected" multiple name="selections[]">
      <option v-for="option in options" v-bind:value="valueString(option)">
        {{ option.company_name }}
      </option>
    </select>
  </div>
</template>
<script>
    export default {
        props:{
          accountId: {type: Number}
        },

        data() {
          return {
            selected: '',
            options: '',
            dataReady: false
          }
        },

        mounted() {
          this.processData();
        },

        methods:{
          valueString(option){
            return JSON.stringify(option);
          },

          async getList(
                  accountId
                  ){
            try{
              return await axios.get('/getContractorList',{
                params: {
                  accountId: accountId
                }
              })
            }
            catch (error) {
              console.log(error);
            }
          },

          processData(){
            this.getList(this.accountId).then(response => {

              //Set response to data and labels
              this.options = response.data;
              console.log('Ahhh:' + this.options);

              //Set render flag
              this.dataReady = true;
            })
            .catch(error => {
              console.log(error);
              this.dataReady = false;
            });
          }
        }
      }
  </script>
  <style scoped>

  </style>
