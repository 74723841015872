<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-body">
        <canvas v-bind:id="'canvas' + this._uid"></canvas>
      </div>
    </div>
  </div>
</template>

<script>

import { EventBus } from '../../event-bus.js';
export default{
  props:{
    titleText: {type: String, default: ''},
    titleDisplay: {typ: Boolean, default: false},

    stacked: {type: Boolean, default: false},

    xAxesGridlines: {type: Boolean, default: false},
    xAxesDisplay: {type: Boolean, default: true},

    yAxesGridlines: {type: Boolean, default: true},
    yAxesDisplay: {type: Boolean, default: true},
    yAxesLabel: {type: String, default: ''},
    yAxesLabelDisplay: {type: Boolean, default: true},

    liveDataSets: {type: Array, required: true},
    chartType : {type: String, required: true},
    measureName : null

  },
  data(){
    return {
      firstLoad: true,
      chart: null,
      canvas: null,
      backgroundColors: [],
      datasets: [],
      labels: [],
      chartData: [],
      options: {
        tooltips: {
          enabled: true
        },
        scales: {
          xAxes: [{
            display:this.xAxesDisplay,
            type: 'category',
            stacked: this.stacked,
            gridLines: {
              display:this.xAxesGridlines
            }
          }],
          yAxes: [{
              scaleLabel: {
                labelString: this.yAxesLabel,
                display: this.yAxesLabelDisplay
              },
              display:this.yAxesDisplay,
              stacked: this.stacked,
              gridLines: {
                display:this.yAxesGridlines
              }
          }]
        },
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: this.titleDisplay,
          text: this.titleText
        }
      }
    }
  },

  watch:{
    chartData: {
      deep: true,
      handler(){
        this.chart.update();
        console.log('changed');
      }
    }
  },

  methods:{

    async getData(liveDataSet){
      try{
        return await axios.get('/getLiveData',{
          params: {
            liveDataSet: liveDataSet
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },

    async initialiseChart(){

      this.getData(this.liveDataSets[0].name).then(response => {
        //Set response to data and labels
        this.labels = response.data.map(dimension => dimension.dimension);

        this.chartData[0] = response.data.map(measure => measure.measure);

        this.datasets.push({
          label: this.liveDataSets[0].label,
          backgroundColor: this.liveDataSets[0].color,
          borderColor: this.liveDataSets[0].color,
          fill: false,
          borderWidth: 2,
          data: this.chartData[0]
        });

        this.chart = new Chart(this.canvas, {
                     type: this.chartType,
                     data: {
                            labels: this.labels,
                            datasets: this.datasets
                          },
                     options: this.options
                  });

        this.liveDataSets.slice(1,this.liveDataSets.length).forEach((liveDataSet, index) => {
          this.getData(liveDataSet.name).then(response => {

            this.chartData[index+1] = response.data.map(measure => measure.measure);

            this.datasets.push({
              label: liveDataSet.label,
              backgroundColor: liveDataSet.color,
              borderColor: liveDataSet.color,
              fill: false,
              borderWidth: 2,
              data: this.chartData[index+1]
            });

            this.chart.update();

          })
          .catch(error => {
            console.log(error);
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
    },

    // Assumption here is that x axis sets are the same for each result (may not be the case..revise a nother time)
    async processData(){

      this.liveDataSets.forEach((item, index) => {
        this.getData(item.name).then(response => {
          this.chartData[index] = response.data.map(measure => measure.measure);
          this.chart.data.datasets[index].data = this.chartData[index];
          this.chart.update();
        })
        .catch(error => {
          console.log(error);
          this.dataready = false;
        });
      });
    }

  },
  mounted() {
    this.canvas = document.getElementById('canvas' + this._uid).getContext('2d');
    this.initialiseChart();

    EventBus.$on('liveUpdate', (message) => {
      this.processData();
    });
  }
}

	</script>
