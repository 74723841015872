<template>
  <div>
    <multi-select v-for="column in columns" :key="column.name"
      :column="column"
      :data-source="dataSource"
      :date-sorter="column.sortby"
      :mode="mode"
      :user-id="userId"
    />
  </div>
</template>
<script>
  export default {
    props:{
      dataSource: {type: String, required: true},
      columns: {type: Array, required: true},
      mode: {type: String, default: 'global'},
      userId: {type: Number, required: false}
    }
  }
</script>
