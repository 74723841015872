<template>
<div class="mb-4">

  <div v-bind:class="borderColor" class="card shadow h-100 py-2">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col mr-2">
          <div v-bind:class="textColor" class="text-xs font-weight-bold text-uppercase mb-1">{{kpiLabel}}</div>
          <div v-if="kpiReady" class="h5 mb-0 font-weight-bold text-gray-800">
            <a v-on:click="modalGo(modalHashId)">{{kpiPrefix}}<span v-if="kpiReady">{{animatedNumber}}</span></a>
          </div>
        </div>
        <div class="col-auto">
          <i v-bind:class="kpiIcon" class="fas fa-2x text-gray-300"></i>
        </div>
      </div>
    </div>
  </div>

    <!-- Modal -->
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{kpiLabel}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div v-if="getDetail">
                    <detail-table
                      :data-source="kpiSource"
                      :data-filter="dataFilter"
                      :data-columns="detailColumns"
                      :data-sortby="detailSortby"
                      :data-limit="dataLimit"
                    ></detail-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div style="float:right;margin-bottom:10px" v-if="allowExport">
            <a class="btn btn-primary" :href="fullExportRoute">Export</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
  import { EventBus } from '../../event-bus.js';
  import DetailTable from './DetailTable.vue'
  export default {
    components:{
      DetailTable
    },
    props: {
      kpiSource: null,
      kpiField: null,
      kpiFunction: null,
      kpiPrefix: null,
      kpiLabel: '',
      kpiIcon: '',
      kpiThemeColor: {type: String, default: 'primary'},
      kpiGroupFunction: {type: String, default: null},
      kpiGroupDimension: {type: String, default: null},
      dataFilter: {type: Object},
      dataLimit: {type: Number, default: null},
      detailColumns: {type: Object},
      detailSortby: '',
      allowExport: '',
      exportRoute: '',
      dataBinding: {type: String, default: 'dynamic'}
    },

    data () {
      return {
        kpiResult: 0,
        kpiResultTween: 0,
        kpiReady: false,
        tweenedNumber: 0,
        modalId: null,
        modalHashId: null,
        getDetail: false
      }
    },

    computed: {
      borderColor: function(){
        return 'border-left-' + this.kpiThemeColor;
      },
      textColor: function(){
        return 'text-' + this.kpiThemeColor;
      },
      animatedNumber: function() {
        if(isNaN(this.$data.tweenedNumber)){
          //console.log(this.$data.tweenedNumber);
          return this.kpiResult;
        }
        else{
          return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      fullExportRoute: function(){
        if(this.dataFilter){
          return this.exportRoute + '?dataColumns=' + JSON.stringify(this.detailColumns.columns) + '&dataFilter=' + JSON.stringify(this.dataFilter);
        }
        else{
          return this.exportRoute + '?dataColumns=' + JSON.stringify(this.detailColumns.columns) + '&dataFilter=all';
        }

      }
    },

    watch: {
      kpiResult: function(newValue) {
        TweenLite.to(this.$data, 0.5, { tweenedNumber: newValue });
      }
    },

    mounted() {
      this.processKPIData();
      this.modalHashId = '#modal_' + this._uid;
      this.modalId = 'modal_' + this._uid;

      if(this.dataBinding == 'dynamic'){
        EventBus.$on('sessionFilterChange', (message) => {
          this.getDetail = false;
          this.processKPIData();
        });
        EventBus.$on('globalFilterChange', (message) => {
          this.getDetail = false;
          this.processKPIData();
        });
      }
    },

    methods:{

      modalGo(modalHashId){
        console.log('modal click' + modalHashId);
        this.getDetail = true;
        window.jQuery(modalHashId).modal();
      },
      //Start KPI Data Stuff

      async getKPIData(
              kpiSource,
              kpiField,
              kpiFunction,
              kpiGroupFunction,
              kpiGroupDimension,
              dataFilter){
        try{
          return await axios.get('/getKPI',{
            params: {
              kpiSource: kpiSource,
              kpiField: kpiField,
              kpiFunction: kpiFunction,
              kpiGroupFunction : kpiGroupFunction,
              kpiGroupDimension : kpiGroupDimension,
              dataFilter: dataFilter
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },

      processKPIData(){
        console.log('Requesting KPI data...- kpi-spark-generic - processKPIData');
        this.getKPIData(
          this.kpiSource,
          this.kpiField,
          this.kpiFunction,
          this.kpiGroupFunction,
          this.kpiGroupDimension,
          this.dataFilter
        ).then(response => {
          console.log('Response received...- kpi-spark-generic - processKPIData');

          //Set response to data and labels
          this.kpiResult = response.data[0].measure;
          console.log(response.data[0].measure);

          //Set render flag
          this.kpiReady = true;
        })
        .catch(error => {
          console.log(error);
          this.kpiReady = false;
        });
      }

      //End KPI Data Stuff
    }
  }
</script>
