<template>
    <div v-if="filtersApplied" class="spinner-grow text-primary" role="status" style="vertical-align:middle;width:1rem;height:1rem;">
    </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    data() {
      return {
        hasGlobalFilters: false,
        hasTagFilters: false,
      }
    },

    computed: {
      filtersApplied: function(){
        return this.hasGlobalFilters === 'true' || this.hasTagFilters === 'true';
      }
    },

    mounted() {
      this.processData();
      EventBus.$on('globalFilterChange', (message) => {
        this.processData();
      });
    },

    methods: {
      async checkGlobal(){
        try{
          return await axios.get('/filter/check?mode=global');
        }
        catch (error) {
          console.log(error);
        }
      },

      async checkTag(){
        try{
          return await axios.get('/filter/check?mode=tag');
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.checkGlobal().then(response => {
          this.hasGlobalFilters = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });

        this.checkTag().then(response => {
          this.hasTagFilters = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
  }
</script>
