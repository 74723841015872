import { EventBus } from '../../event-bus.js';
import {Bar} from 'vue-chartjs'
export default {
  extends: Bar,
  props: {
    tooltipPrefix: {type: String, default: ''},
    tooltipSuffix: {type: String, default: ''},
    chartDatasets: {
      type: Array | Object,
      required: false,
      default: null
    },
    chartLabels: {
      type: Array,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            fontSize: 8
          }
        },
        responsive: true,
        scales: {
          xAxes: [ {
              display:true,
              gridLines: {
                color: 'transparent',
                zeroLineColor: 'transparent'
              },
              ticks: {
                fontSize: 8,
                fontColor: '#ffffff'
              }
          } ],
            yAxes: [ {
                display:false,
                ticks: {
                    display: false,
                }
            } ]
        },
        title: {
            display: false,
        },
        elements: {
          line: {
              tension: 0.00001,
              borderWidth: 1
          },
          point: {
              radius: 4,
              hitRadius: 10,
              hoverRadius: 4
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return this._chart.options.tooltips.prefix + tooltipItems.yLabel.toLocaleString() + this._chart.options.tooltips.suffix;
            }
          },
          prefix: null
        }
      })
    }
  },
  mounted () {
    this.chartDatasets.backgroundColor = '#ffffff';
    this.chartOptions.tooltips.prefix = this.tooltipPrefix;
    this.chartOptions.tooltips.suffix = this.tooltipSuffix;
    this.callRender();
  },
  methods:{
    callRender(){
      //this.chartDatasets.backgroundColor = randomColor({count: this.chartDatasets.data.length, luminosity: 'dark', step: 2});

      this.renderChart({
        labels: this.chartLabels,
        datasets: [this.chartDatasets],
      }, this.chartOptions);
    }
  },
  watch: {
    'chartDatasets.data' : 'callRender'
  }
}
