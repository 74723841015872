<template>
<div>
  <div v-if="showDetail">
    <div>
      <a v-on:click="showModal"><i style="font-size:2rem;" class="fas fa-eye eye-flash"></i></a>
    </div>

    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModal" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="detailModal">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body overflow-auto">
                    <detail-table
                      :title="title"
                      :data-source="dataSource"
                      :data-filter="dataFilter"
                      :data-columns="dataColumns"
                      :data-sortby="dataSortby"
                      :data-limit="dataLimit"
                      :display-limit="displayLimit"
                      :mainteno-link-field="maintenoLinkField"
                      :mainteno-account-path="maintenoAccountPath"
                    ></detail-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div style="float:right;margin-bottom:10px" v-if="allowExport">
              <a class="btn btn-primary" :href="fullExportRoute">Export</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <a data-toggle="tooltip" data-placement="bottom" v-bind:title="detailTitle"><i style="font-size:2rem;" class="fas fa-eye-slash"></i></a>
    </div>
  </div>
</div>
</template>
<script>
  import { EventBus } from '../../event-bus.js';
  import DetailTable from '../visual/DetailTable.vue';
  export default {
    components:{
      DetailTable
    },

    props: {
      title: {type: String, required: true},
      dataSource: {type: String, required: true},
      dataFilter: {type: Object, required: false, default: null},
      dataColumns: {type: Object, required: true},
      dataLimit: {type: Number, required: false, default: null},
      displayLimit: {type: Number, required: false, default: null},
      dataSortby: {type: String, required: false, default: null},
      maintenoLinkField: {type: String, required: false, default: null},
      maintenoAccountPath: {type: String, required: false, default: null},
      allowExport: {type: Boolean, required: false, default: false},
      exportRoute: {type: String, required: false, default: null}
    },

    data () {
      return {
        openable: false,
        recordCount: null
      }
    },

    computed: {
      showDetail: function(){
        if(this.recordCount > 0 && this.recordCount < this.dataLimit){
          return true;
        }
        else{
          return false;
        }
      },

      detailTitle: function(){
        return "Sorry. Too many records (" + this.recordCount + ")";
      },

      fullExportRoute: function(){
        if(this.dataFilter){
          return this.exportRoute + '?dataColumns=' + JSON.stringify(this.dataColumns.columns) + '&dataFilter=' + JSON.stringify(this.dataFilter);
        }
        else{
          return this.exportRoute + '?dataColumns=' + JSON.stringify(this.dataColumns.columns) + '&dataFilter=all';
        }
      }
    },

    mounted: function() {
      this.processData();
      EventBus.$on('sessionFilterChange', (message) => {
        this.processData();
      });
      EventBus.$on('globalFilterChange', (message) => {
        this.processData();
      });
    },

    methods:{

      showModal(){
        window.jQuery('#detailModal').modal();
      },

      toggleme(){
        this.openable = true;
        this.processData();
      },

      async getData(
            dataSource,
            dataFilter,
            dataLimit){
        try{
          return await axios.get('/getRecordCount',{
            params: {
              dataSource: dataSource,
              dataFilter: dataFilter,
              dataLimit: dataLimit
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.getData(
          this.dataSource,
          this.dataFilter,
          this.dataLimit
        ).then(response => {
          this.recordCount = response.data[0].recordcount;
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
  }
</script>
<style scoped>
.eye-flash {
  color: #222;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes color {
  0% {
    color: #222;
  }
  50% {
    color: #4285f4;
  }
  100 {
    color: #222;
  }
  }
</style>
