<template>
  <div class="overflow-auto">
    <!-- <label>Search:</label>
    <input class="form-control" v-model="filters.all.value"/> -->

    <v-table class="table table-sm table-striped table-hover table-condensed"
      v-if="dataReady"
      :data="realData"
      :currentPage.sync="currentPage"
      :pageSize="displayLimit"
      @totalPagesChanged="totalPages = $event"
      :filters="filters"
    >
      <thead slot="head" class="mb-1">
        <th>
          <select v-model="selectedDimension" v-on:change="processData">
            <option disabled>-- Schedule --</option>
            <option id="schedule_identifier" value="schedule_identifier">Schedule Name</option>
            <option disabled>-- Category --</option>
            <option id="category1" value="category1">Category 1</option>
            <option id="category2" value="category2">Category 2</option>
            <option disabled>-- Location --</option>
            <option id="region" value="region">Region</option>
            <option id="site" value="site">Site</option>
            <option disabled>-- Contractor --</option>
            <option id="contractor_name" value="contractor_name">Contractor Name</option>
            <option disabled>-- Priority --</option>
            <option id="priority" value="priority">Priority</option>
          </select>
        </th>
        <v-th v-for="(obj,key,index) in dataColumns['columns'].slice(1,dataColumns['columns'].length)" v-bind:obj="obj" v-bind:key="index" :sortKey="obj" style="white-space:nowrap;">
          {{ dataColumns['labels'].slice(1,dataColumns['labels'].length)[key] }}
        </v-th>
      </thead>
      <tbody slot="body" slot-scope="{displayData}">
        <tr v-for="row in displayData" :key="row.id">
          <td v-for="choice in dataColumns['columns']" class="text-xs" v-bind:class="ragColor(row[choice],choice)">
            {{ row[choice] }} {{ pctSymbol(choice) }}
          </td>
        </tr>
      </tbody>
    </v-table>
    <smart-pagination
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :maxPageLinks="10"
        :boundaryLinks="true"
      />
  </div>

</template>

<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    props:{
      dataSource: {type: String, default: ""},
      dataColumns: {type: Object, required: true},
      displayLimit: {type: Number, default: 15},
      ragConfig: {type: Object, default: null}
    },
    data() {
      return {
        detailData: {type: Object},
        dataReady: {type: Boolean, default: false},
        currentPage: 1,
        totalPages: 0,
        filters: {
          all: { value: '', keys: this.dataColumns['columns'] }
        },
        selectedDimension: 'category2'
      }
    },
    computed: {
      realData: function(){
       if(this.dataReady == true){
        return this.detailData;
       }
       else{
        var dummyData = [{}];
        return dummyData;
       }
     }
    },
    mounted() {
      this.processData();

      EventBus.$on('sessionFilterChange', (message) => {
        this.processData();
      });

      EventBus.$on('globalFilterChange', (message) => {
        this.processData();
      });
    },
    methods:{

      ragColor(value,choice){
        if(this.ragConfig != null && choice == 'due_date_compliance'){
          if(this.ragConfig.direction == 'up'){
            if(value > this.ragConfig.thresholds[1]){
              return 'text-success';
            }
            else{
              if(value > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-danger'
              }
            }
          }
          else{
            if(value > this.ragConfig.thresholds[1]){
              return 'text-danger';
            }
            else{
              if(value > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-success'
              }
            }
          }
        }
        // if(choice == 'schedule_warnings_pct' && value > 0){
        //     return 'text-amber';
        // }
        // if(choice == 'schedules_invalid_pct' && value > 0){
        //     return 'text-danger';
        // }
      },

      pctSymbol(choice){
        if(choice == 'schedules' || choice == 'dimension' || choice == 'no_data'){
          return null;
        }
        else{
          return '%';
        }
      },

      async getData(
              dataSource,
              dimensionField){
        try{
          console.log("Check here");
          console.log(dataSource);
          console.log(dimensionField);
          return await axios.get('/getPPMComplianceData',{
            params: {
              dataSource: dataSource,
              dimensionField: dimensionField
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },
      processData(){
        this.getData(
          this.dataSource,
          this.selectedDimension
        ).then(response => {

          //Set response to data and labels
          this.detailData = response.data;
          //console.log(this);

          //Set render flag
          this.dataReady = true;

        })
        .catch(error => {
          console.log(error);
          this.dataReady = false;
        });
      }
    }
  }
</script>
