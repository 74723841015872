<template>
  <div style="height:200px">
        <component
          v-bind:is="currentChartType"
          :chart-datasets="datasets"
          :chart-labels="labels"
          :selection-mode="selectionMode"
          :chart-color="chartColor"
          :tooltip-prefix="tooltipPrefix"
          :tooltip-suffix="tooltipSuffix"
          :legend-position="legendPosition"
          :y-axes-mode="yAxesMode"
          v-if="dataready"
          >
        </component>
</div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
import DoughnutChart from '../charts/vue-chartjs-doughnut-responsive.js'
import BarChart from '../charts/vue-chartjs-bar-responsive.js'
import HorizontalBarChart from '../charts/vue-chartjs-bar-horizontal-responsive.js'
import LineChart from '../charts/vue-chartjs-line-responsive.js'
import PieChart from '../charts/vue-chartjs-pie-responsive.js'




export default {
  components: {
    DoughnutChart,
    BarChart,
    PieChart,
    LineChart,
    HorizontalBarChart
  },

  props: {
    dataSource: null,
    measureField: null,
    measureName: null,
    measureFunction: null,
    dimensionFields: {type: Object, required: true},
    dataFilter: {type: Object},
    dateSorter: '',
    dataLimit: null,
    dataSortBy: null,
    chartTitle: '',
    chartType : {type: String, required: true},
    //sparkBgStyle: null,
    dataBinding: {type: String, required: true},
    selectionMode: {type: String, required: true},
    autoDrill: {type: String, default: 'false'},

    chartColor: null,
    tooltipPrefix: '',
    tooltipSuffix: '',
    legendPosition: '',
    yAxesMode: {type: String, default: 'normal'}
  },

  data () {
    return {
      showModal: false,
      datasets: null,
      labels: null,
      dataready: false,
      tester: null,
      currentChartType: null,
      selectChartType: [
        { text: 'Bar', value: 'bar-chart' },
        { text: 'Bar (horizontal)', value: 'horizontal-bar-chart' },
        { text: 'Line', value: 'line-chart' },
        { text: 'Doughnut', value: 'doughnut-chart' },
        { text: 'Pie', value: 'pie-chart' }
      ],
      currentDimension: 0,
      thisKey: 1
    }
  },
  computed: {
    drillPath: function(){
      return this.dimensionFields.labels.slice(0,this.currentDimension+1);
    },
    modalName: function(){
      return this.chartTitle.replace(/\s+|[()]+/g, '');
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async expand(){
      this.showModal = true;

      await this.$nextTick();

      await jQuery("#" + this.modalName).on('hidden.bs.modal', function(){
        this.showModal = false;
      });

      await jQuery("#" + this.modalName).modal('show');

      await this.sleep(1000);
      if(this.thisKey == 1){
       this.thisKey = 2;
      };
    },

    clearFilter(value){
      var POSTbody = {
          dataItem: value,
          dataValue: '*',
          mode: 'session'
      }
      axios({
          method: 'post',
          url: '/filter/remove',
          data: POSTbody

      })
      .then(function (response) {
          //console.log(response);
          console.log('Emitting - filter cleared for ' + value);
          //Sending refresh signal to other objects
          EventBus.$emit('sessionFilterChange', ['clear', value]);
      })
      .catch(function (error) {
          console.log(error);
      });
    },

    drillChange(value){
      this.currentDimension = value;
      this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
      this.clearFilter(this.dimensionFields.columns.slice(this.currentDimension));
    },

    amIActive(value){
      console.log('Active called ' + value);

      if(value == this.currentDimension){
        console.log('Active');
        return true;
      }
      else{
        return false;
      }
    },
    init(){
      console.log('Initialise');
    },

    change(){
      //this.processData();
      // this.labels = ['One','Two'];
      // this.datasets.data = [56,78];
    },
    async getData(dataSource,
            measureField,
            measureFunction,
            dimensionField,
            dataFilter,
            dateSorter,
            dataLimit,
            dataSortBy){
      try{
        return await axios.get('/getData',{
          params: {
            dataSource: dataSource,
            measureField: measureField,
            measureFunction: measureFunction,
            dimensionField: dimensionField,
            dataFilter: dataFilter,
            dateSorter: dateSorter,
            dataLimit: dataLimit,
            dataSortBy: dataSortBy
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },
    processData(){
      console.log('Requesting data...');
      this.getData(
        this.dataSource,
        this.measureField,
        this.measureFunction,
        this.dimensionFields.columns[this.currentDimension],
        this.dataFilter,
        this.dateSorter,
        this.dataLimit,
        this.dataSortBy
      ).then(response => {
        console.log('Response received...');

        //Set response to data and labels
        this.labels = response.data.map(dimension => dimension.dimension);
        this.datasets.data = response.data.map(measure => measure.measure);

        //Autodrill code
        if(this.autoDrill == 'true' && this.datasets.data.length == 1 && this.currentDimension < this.dimensionFields.columns.length-1){
          console.log('Skipping down a drill level from ' + this.dimensionFields.columns[this.currentDimension] + ' to ' + this.dimensionFields.columns[this.currentDimension+1]);
          this.currentDimension++;
          this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
          this.processData();
        }
        else{
          //Set render flag
          this.dataready = true;
        }
      })
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },
  created () {
    console.log('Component Created');
  },
  mounted () {
    //console.log('Mounting component' + this.selectionMode);
    this.currentChartType = this.chartType;

    //Setting up event bus methods

    if(this.dataBinding == 'dynamic'){
      EventBus.$on('sessionFilterChange', (message) => {
        if(message[0] == 'add'){
          if(this.dimensionFields.columns[this.currentDimension] == message[1]){
            if(this.dimensionFields.columns.length-1 > this.currentDimension){
              this.currentDimension++;
              this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
              console.log('Adding');
            }
          }
        }else if (message[0] == 'remove') {
          if(this.dimensionFields.columns[this.currentDimension] == message[1]){
            if(this.currentDimension != 0){
              this.currentDimension--;
              this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
            }
          }
        }else if (message[0] == 'clear') {
          //Nothing to do really
          //Check if you have dimension in list, if yes, are you below it? If yes, go up to it.
          var inMyList = this.dimensionFields.columns.indexOf(message[1][0]);
          console.log('Here? ' + inMyList);
          if(inMyList != -1 && this.currentDimension > inMyList){
            this.currentDimension = inMyList;
            this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
          }
        }

        this.processData();
      });

      EventBus.$on('globalFilterChange', (message) => {
        this.processData();
      });
    }

    this.labels = ['No Data'];

    //Set defaults
    this.datasets =
      {
        label: this.measureName,
        data: [0],
        dimension: this.dimensionFields.columns[this.currentDimension]
      }
    ;

    //Make initial data Request
    console.log('Initial data request...');
    this.processData();
  }
}
</script>
