<template>
  <div>
    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">{{titleText}}</h6>

        <a v-on:click="expand"><i class="fas fa-expand-arrows-alt text-muted" style="vertical-align:middle;margin-left:10px; font-size:1rem"></i></a>

      </div>
      <!-- Card Body -->
      <div class="card-body">
        <canvas v-bind:id="'canvas' + this._uid"></canvas>
      </div>
    </div>

  </div>
</template>
<script>
export default{
  props:{
    titleText: {type: String, default: ''},
    titleDisplay: {typ: Boolean, default: false},
    stacked: {type: Boolean, default: false},
    xAxesGridlines: {type: Boolean, default: false},
    xAxesDisplay: {type: Boolean, default: true},
    yAxesGridlines: {type: Boolean, default: true},
    yAxesDisplay: {type: Boolean, default: true},
    measures: null

  },
  data(){
    return {
      firstLoad: true,
      chart: null,
      canvas: null,
      chartType: 'bar',
      backgroundColors: [],
      datasets: [],
      chartData: [],
      dimensionField: 'occurred_on_month',
      dimensionType: 'category',
      // measures: [
      //   {
      //     "label":"Spend",
      //     "table":"sales_aggregate_1",
      //     "function":"sum",
      //     "column":"TotalPrice"
      //   },
      //   {
      //     "label":"Budget",
      //     "table":"sales_aggregate_1",
      //     "function":"sum",
      //     "column":"Budget"
      //   }
      // ],
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        },
        scales: {
          xAxes: [{
            display:this.xAxesDisplay,
            type: 'category',
            stacked: this.stacked,
            gridLines: {
              display:this.xAxesGridlines
            }
          }],
          yAxes: [{
              scaleLabel: {
                labelString: "Faults",
                display: true
              },
              display:this.yAxesDisplay,
              stacked: this.stacked,
              gridLines: {
                display:this.yAxesGridlines
              },
              ticks: {
                callback: function(value,index,values){
                  return Math.round((value/1000)) + 'k';
                }
              }
          }]
        },
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: this.titleDisplay,
          text: this.titleText
        }
      }
    }
  },
  // watch:{
  //   chartType: function(){
  //     console.log('Rendering bar');
  //     console.log(this.ctx);
  //
  //     var renderChart new Chart(this.ctx, {
  //       type: this.chartType,
  //       data: barChartData,
  //       options: this.options
  //     });
  //   }
  // },
  methods:{
    async expand(){
      this.showModal = true;

      await this.$nextTick();

      await jQuery("#" + this.modalName).on('hidden.bs.modal', function(){
        this.showModal = false;
      });

      await jQuery("#" + this.modalName).modal('show');

      this.canvasMax = document.getElementById('canvasMax' + this._uid).getContext('2d');
      this.chart = new Chart(this.canvasMax, {
         type: this.chartType,
         data: {
                labels: this.labels,
                datasets: this.datasets
              },
         options: this.options
      });

      //await this.sleep(1000);
      if(this.thisKey == 1){
       this.thisKey = 2;
      };
    },
  async getData(
          measures,
          dimensionField,
          dimensionType,
          dataFilter,
          dataLimit){
    try{
      return await axios.get('/getDataMulti',{
        params: {
          measures: JSON.stringify(measures),
          dimensionField: dimensionField,
          dimensionType: dimensionType,
          dataFilter: dataFilter,
          dataLimit: dataLimit
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  },

  async processData(){
    this.getData(
      this.measures,
      this.dimensionField,
      this.dimensionType,
      this.dataFilter,
      this.dataLimit
      ).then(response => {
        //Set response to data and labels
        this.labels = response.data.map(dimension => dimension.dimension);

        //Get measure keys into array
        var keys = Object.keys(response.data[0]);
        keys = keys.splice(1,keys.length-1);

        keys.forEach(function(key,keyIndex){
          this.chartData[keyIndex] = [];
          response.data.forEach(function(data,dataIndex){
            this.chartData[keyIndex].push(data[key]);
          }.bind(this));
        }.bind(this));

        //Only set once as will equal n_measures always
        if(this.backgroundColors.length == 0){
          this.backgroundColors = randomColor({count: this.measures.length, luminosity: 'dark', seed: 3, format: 'rgba', alpha: 1});
        }


        //var borderColors = randomColor({count: this.measures.length, luminosity: 'dark', seed: 6});
        this.datasets = [];
        console.log(this.backgroundColors);
        console.log(this.measures.length);
        this.measures.forEach(function(measure,index){
          this.datasets.push({
            label: measure.label,
            //backgroundColor: color(window.chartColors.red).alpha(0.5).rgbString(),
            backgroundColor: this.backgroundColors[index],
            borderColor: this.backgroundColors[index],
            fill: false,
            borderWidth: 2,
            data: this.chartData[index]
          });

        }.bind(this));

        if(this.firstLoad){
          this.chart = new Chart(this.canvas, {
             type: this.chartType,
             data: {
                    labels: this.labels,
                    datasets: this.datasets
                  },
             options: this.options
          });
          this.firstLoad = false;
        }else{
          this.chart.data.labels = this.labels;
          this.chart.data.datasets = this.datasets;
          this.chart.update();
        }


//        this.dataready = true;
        }
      )
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },
  mounted() {

    this.canvas = document.getElementById('canvas' + this._uid).getContext('2d');
    console.log('Mounting multi');
    //
    // this.chart = new Chart(this.canvas, {
    //   type: this.chartType,
    //   data: {labels: ['No Data'], datasets: {data: [0]}},
    //   options: this.options
    // });

    this.processData();

    // setTimeout(() => {
    //   console.log('changing...');
    //   this.processData();
      // this.chart.destroy();
      // this.chart = new Chart(this.canvas, {
      //   type: 'pie',
      //   data: {labels: this.labels, datasets: this.datasets},
      //   options: this.options
      // });
      // this.chart.data.labels = this.labels;
      // this.chart.data.datasets = this.datasets;

    // },5000);
  }
}

	</script>
