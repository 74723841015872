<template>
  <div class="d-flex align-content-middle">
    <h5 class="mr-2 text-gray-800">Live</h5>
    <div class="spinner-grow spinner-grow-sm text-danger" role="status">
    </div>
  </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';

  export default {
    props: {
      interval: {type: Number, default: 10000}
    },

    data () {
      return {
        polling: null
      }
    },

    created () {
      this.liveRefresh();
    },

    beforeDestroy () {
    	clearInterval(this.polling);
    },

    methods:{
      liveRefresh () {
        this.polling = setInterval(() => {
          EventBus.$emit('liveUpdate');
        }, this.interval)
      }
    }
  }

</script>
