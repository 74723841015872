<template>
  <div class="mx-5">
    <div v-if="tasks" class="ticker box">
      <article v-for="task in tasks.slice(0,3)" class="media">
        <span class="breaking-news media-left px-2">{{task.reference}}</span>
        <div class="media-content align-middle">
          <span class="text-primary">{{task.site}} | </span>
          <transition name="fade" tag="div" mode="out-in">
            <span class="news">{{task.category}} | <i>{{task.description}}</i></span>
          </transition>
        </div>
       </article>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';

export default {
  props: {
    interval: {type: Number, default: 20000},
    taskType: {type: String, default: 'all'},
  },

  data() {
    return {
      tasks: null,
      taskShow : [true,true,true,false,false,false,false,false,false,false],
      currentTask: {type: Object, default: null},
    }
  },

  created () {
    setInterval(this.updateTicker, this.interval);
  },

  methods: {
    updateTicker: function() {
      var removed = this.tasks.pop();
      this.tasks.unshift(removed);
      removed = this.tasks.pop();
      this.tasks.unshift(removed);

      // var removed = this.taskShow.pop();
      // this.taskShow.unshift(removed);
      // removed = this.taskShow.pop();
      // this.taskShow.unshift(removed);
      // removed = this.taskShow.pop();
      // this.taskShow.unshift(removed);
    },


    async getData(){
      try{
        return await axios.get('/getLiveTasks',{
          params: {
            taskType: this.taskType
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },

    processData(){
      console.log('Requesting data...');
      this.getData().then(response => {
        console.log('Response received...');

        this.tasks = response.data;

        this.currentTask = this.tasks[0];

        this.dataready = true;

      })
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },

  mounted(){

    EventBus.$on('liveUpdate', (message) => {
      this.processData();
    });

    this.processData();
  }
}
</script>

<style scoped>
  .breaking-news {
  background-color: #a30f0f;
  color: #ffffff;
  border-radius: 10px 50px 50px 10px;
  padding: 2px;
  margin-top: 2px;
  }

  .media-content {
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }

  .time {
  color: #33A3F1;
  }

  .news {
  color: #666666;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s
  }

  .fade-enter, .fade-leave-to {
    opacity: 0
  }
</style>
