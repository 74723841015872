<template>
  <div>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center mb-4">
      <h1 class="h3 mb-0 text-gray-800">Due / Overdue Summary</h1><small> (Experimental)</small>
    </div>



    <!-- Content Row -->
    <div class="container">
      <div class="row align-items-center">
        <div class="col text-center">
          <h2>Overall</h2>
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="row mt-2 pb-4 border-bottom border-secondary">
      <div class="col-md-3">
        <!-- <overdue-card
          :mainteno-link-field = "'reference'"
          :kpi-source = "'faults'"
          :kpi-field = "'reference'"
          :kpi-function = "'count'"
          :kpi-prefix = "''"
          :kpi-label = "'Overdue %'"
          :kpi-icon = "'fas fa-question'"
          :data-filter = "{overdue:['Y'],status:['Allocated','Pending']}"
          :detail-columns = "{columns: ['reference','occurred_on','site','priority'],
                              labels: ['Reference','Occurred On','Site','Priority']}"
          :detail-sortby = "'occurred_on'"
          >
        </overdue-card> -->
        <kpi-spark
        :spark-type = "'spark-line'"
        :data-source = "'faults'"
        :measure-field = "'reference'"
        :measure-function = "'count'"
        :data-filter = "{status:['Pending','Allocated'],overdue:['Y']}"
        :dimension-field = "'due_on_month'"
        :kpi-source = "'faults'"
        :kpi-field = "'reference'"
        :kpi-function = "'count'"
        :kpi-prefix = "''"
        :info-string = "'Overdue'"
        :spark-bg-style = "'spark-bg-red'"
        :data-binding = "'dynamic'"
        >
      </kpi-spark>

    </div>


    <div class="col-md-3">
      <chart-js-native
        :chart-type = "'bar'"
        :chart-title = "'Overdue / Inbound'"
        :data-source = "'faults'"
        :data-filter = "{status:['Pending','Allocated'],overdue:['Y']}"
        :date-sorter = "''"
        :measure-field = "'reference'"
        :measure-name = "'Days Overdue'"
        :measure-function = "'count'"
        :dimension-fields = "{columns:['due_date_delta_days'],labels:['Days']}"
        :data-binding = "'dynamic'"
        :selection-mode = "'active'"
        :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['desc']}"
        :y-axes-position = "'right'"
        :chart-background-color="'#b85656'">
      </chart-js-native>
    </div>
    <div class="col-md-3">

      <chart-js-native
        :chart-type = "'bar'"
        :chart-title = "'Overdue / Inbound'"
        :data-source = "'faults'"
        :data-filter = "{status:['Pending','Allocated'],overdue:['N']}"
        :date-sorter = "''"
        :measure-field = "'reference'"
        :measure-name = "'Due In (days)'"
        :measure-function = "'count'"
        :dimension-fields = "{columns:['due_in_days'],
        labels:['Days']}"
        :data-binding = "'dynamic'"
        :selection-mode = "'active'"
        :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['asc']}"
        :chart-background-color="'#5bb064'">

      </chart-js-native>
    </div>
    <div class="col-md-3">
        <chart-js-native
        :chart-type = "'pie'"
        :chart-title = "'Status'"
        :data-source = "'faults'"
        :data-filter = "{status:['Pending','Allocated'],overdue:['Y','N']}"
        :date-sorter = "''"
        :measure-field = "'reference'"
        :measure-name = "'Status'"
        :measure-function = "'count'"
        :dimension-fields = "{columns:['process_status'],
        labels:['Status']}"
        :data-binding = "'dynamic'"
        :selection-mode = "'active'"
        :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['desc']}"
        :legend-position = "'right'"
        >

      </chart-js-native>
    </div>
  </div>

  <!-- Content Row -->
  <div class="container">
    <div class="row align-items-center">
      <div class="col text-center mt-2">
        <h2>Breakdown</h2>
      </div>
    </div>
  </div>

  <div class="mb-4">
    Split by:
    <select v-model="selectedDimension" v-on:change="processData">
      <option disabled>-- Category --</option>
      <option id="category1" value="category1">Category 1</option>
      <option id="category2" value="category2">Category 2</option>
      <option disabled>-- Location --</option>
      <option id="region" value="region">Region</option>
      <option id="site" value="site">Site</option>
      <option id="location1" value="location1">Location 1</option>
      <option id="location2" value="location2">Location 2</option>
      <option disabled>-- Contractor --</option>
      <option id="contractor_name" value="contractor_name">Contractor Name</option>
      <option disabled>-- Priority --</option>
      <option id="priority" value="priority">Priority</option>
    </select>
  </div>

  <div v-for="row in datasets" :key="row.dimension">
    <div v-if="row.overdue > 0">
    <!-- Content Row -->
    <div class="row mt-2">
      <div class="col">
        <h5><u>{{ row.dimension }}</u></h5>
      </div>
    </div>

    <!-- Content Row -->
    <div class="row mt-2 border-bottom">

      <div class="col-md-3">
        <kpi-spark
          :spark-type = "'spark-line'"
          :data-source = "'faults'"
          :measure-field = "'reference'"
          :measure-function = "'count'"
          :data-filter = "dynamicFilter({status:['Pending','Allocated'],overdue:['Y']},row.dimension)"
          :dimension-field = "'due_on_month'"
          :kpi-source = "'faults'"
          :kpi-field = "'reference'"
          :kpi-function = "'count'"
          :kpi-prefix = "''"
          :info-string = "'Overdue'"
          :spark-bg-style = "bgColor()"
          :data-binding = "'dynamic'">
        </kpi-spark>
      </div>

      <div class="col-md-3">
        <chart-js-native
          :chart-type = "'bar'"
          :chart-title = "'Overdue / Inbound'"
          :data-source = "'faults'"
          :data-filter = "dynamicFilter({status:['Pending','Allocated'],overdue:['Y']},row.dimension)"
          :date-sorter = "''"
          :measure-field = "'reference'"
          :measure-name = "'Days Overdue'"
          :measure-function = "'count'"
          :dimension-fields = "{columns:['due_date_delta_days'],
          labels:['Days']}"
          :data-binding = "'dynamic'"
          :selection-mode = "'active'"
          :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['desc']}"
          :y-axes-position = "'right'"
          :chart-background-color="'#b85656'">
        </chart-js-native>
      </div>

      <div class="col-md-3">
        <chart-js-native
          :chart-type = "'bar'"
          :chart-title = "'Overdue / Inbound'"
          :data-source = "'faults'"
          :data-filter = "dynamicFilter({status:['Pending','Allocated'],overdue:['N']},row.dimension)"
          :date-sorter = "''"
          :measure-field = "'reference'"
          :measure-name = "'Due In (days)'"
          :measure-function = "'count'"
          :dimension-fields = "{columns:['due_in_days'],
          labels:['Days']}"
          :data-binding = "'dynamic'"
          :selection-mode = "'active'"
          :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['asc']}"
          :chart-background-color="'#5bb064'">
        </chart-js-native>
      </div>

      <div class="col-md-3">
        <chart-js-native
          :chart-type = "'pie'"
          :chart-title = "'Status'"
          :data-source = "'faults'"
          :data-filter = "dynamicFilter({status:['Pending','Allocated'],overdue:['Y']},row.dimension)"
          :date-sorter = "''"
          :measure-field = "'reference'"
          :measure-name = "'Status'"
          :measure-function = "'count'"
          :dimension-fields = "{columns:['process_status'],
          labels:['Status']}"
          :data-binding = "'dynamic'"
          :selection-mode = "'active'"
          :data-sort-by = "{type:['dimension'],columns:['dimension'],direction:['desc']}"
          :legend-position = "'right'">
        </chart-js-native>
      </div>

    </div>
  </div>
</div>
</div>
</template>

<script>
import { EventBus } from '../../event-bus.js';

export default {

  props: {
    dataSource: null
  },

  data () {
    return {
      datasets: null,
      dataready: false,
      selectedDimension: 'category1'
    }
  },


  methods: {
    bgColor(){
      if(Math.random() >= 0.5){
        return 'spark-bg-green';
      }
      else{
        if(Math.random() >= 0.25){
          return 'spark-bg-orange';
        }
        else{
          return 'spark-bg-red';
        }
      }
    },

    percentage(completed,pending,allocated){
      return Math.round((completed/(completed+pending+allocated))*100) || 0;
    },

    dynamicFilter(baseFilter,dimension){
      baseFilter[this.selectedDimension] = [dimension];
      return baseFilter;
    },

    async getData(
            dataSource,
            dimensionField
            ){
      try{
        return await axios.get('/getComplianceData',{
          params: {
            dataSource: dataSource,
            dimensionField: dimensionField
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },

    processData(){
      console.log('Requesting data...');
      this.getData(
        this.dataSource,
        this.selectedDimension
      ).then(response => {
        console.log('Response received...');

        this.datasets = response.data;

        this.dataready = true;

      })
      .catch(error => {
        console.log(error);
        this.dataready = false;
      });
    }
  },

  mounted () {
    this.processData();
  }
}
</script>
