import { EventBus } from '../../event-bus.js';
import {Line} from 'vue-chartjs'
export default {
  extends: Line,
  props: {
    tooltipPrefix: {type: String, default: ''},
    tooltipSuffix: {type: String, default: ''},
    chartDatasets: {
      type: Array | Object,
      required: false,
      default: null
    },
    chartLabels: {
      type: Array,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: 'bottom'
        },
        responsive: true,
        scales: {
            xAxes: [ {
                display:true,
                gridLines: {
                    color: 'transparent',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                  fontSize: 8
                }
            } ],
            yAxes: [ {
                display:false,
                ticks: {
                    display: false,
                }
            } ]
        },
        title: {
            display: false,
        },
        elements: {
          line: {
              borderWidth: 1
          },
          point: {
              radius: 2,
              hitRadius: 5,
              hoverRadius: 5
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return this._chart.options.tooltips.prefix + tooltipItems.yLabel.toLocaleString() + this._chart.options.tooltips.suffix;
            }
          },
          prefix: null
        }
      })
    }
  },
  mounted () {
    this.callRender();
  },
  methods:{
    callRender(){
      // if(!this.chartColor){
      this.chartDatasets.pointBackgroundColor = randomColor({count: this.chartDatasets.data.length, luminosity: 'light', seed: 2});
      // } else{
      //   this.chartDatasets.backgroundColor = this.chartColor;
      // }

      this.chartDatasets.backgroundColor = 'rgba(0,0,0,0.05)';

      this.chartDatasets.fill = true;
      this.chartDatasets.borderColor = '#09596d';

      this.chartDatasets.lineTension = 0.15;
      this.chartOptions.tooltips.prefix = this.tooltipPrefix;
      this.chartOptions.tooltips.suffix = this.tooltipSuffix;

      this.renderChart({
        labels: this.chartLabels,
        datasets: [this.chartDatasets],
      }, this.chartOptions);
    }
  },
  watch: {
    'chartDatasets.data' : 'callRender'
  }
}
