<template>
  <div>
    <canvas ref="myChart"></canvas>
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
import Chart from 'chart.js';

export default {

  props: {
    dataSource: null,
    measureField: null,
    measureName: null,
    measureFunction: null,
    dimensionFields: {type: Object, required: true},
    dataFilter: {type: Object},
    dateSorter: '',
    dataLimit: null,
    dataSortBy: null,
    chartTitle: '',
    chartType : {type: String, required: true},
    //sparkBgStyle: null,
    dataBinding: {type: String, required: true},
    selectionMode: {type: String, required: true},
    autoDrill: {type: String, default: 'false'},

    chartColor: null,
    tooltipPrefix: '',
    tooltipSuffix: '',
    yAxesMode: {type: String, default: 'normal'},
    yAxesPosition: {type: String, default: 'left'},

    legendPosition: {type: String, default: 'bottom'},

    chartBackgroundColor: null,
    chartBorderColor: null,


    },

  data () {
    return {
      datasets: null,
      labels: null,
      dataready: false,
      currentDimension: 0,
      thisKey: 1,
      chart: null,
      startingColors: [
            '#004b66'
            ,'#2f4b7c'
            ,'#665191'
            ,'#a05195'
            ,'#d45087'
            ,'#f95d6a'
            ,'#ff7c43'
            ,'#ffa600'
            ,'#6e00b8'
            ,'#ac0084'
            ,'#c0004f'
            ,'#bc0021'
            ,'#a73100'
            ,'#8a5300'
            ,'#696700'
            ,'#437500'

          ]
    }
  },

  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    clearFilter(value){
      var POSTbody = {
        dataItem: value,
        dataValue: '*',
        mode: 'session'
      }
      axios({
        method: 'post',
        url: '/filter/remove',
        data: POSTbody

      })
      .then(function (response) {
        //console.log(response);
        console.log('Emitting - filter cleared for ' + value);
        //Sending refresh signal to other objects
        EventBus.$emit('sessionFilterChange', ['clear', value]);
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    drillChange(value){
      this.currentDimension = value;
      this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
      this.clearFilter(this.dimensionFields.columns.slice(this.currentDimension));
    },

    amIActive(value){
      console.log('Active called ' + value);

      if(value == this.currentDimension){
        console.log('Active');
        return true;
      }
      else{
        return false;
      }
    },
    async getData(dataSource,
      measureField,
      measureFunction,
      dimensionField,
      dataFilter,
      dateSorter,
      dataLimit,
      dataSortBy){
        try{
          return await axios.get('/getData',{
            params: {
              dataSource: dataSource,
              measureField: measureField,
              measureFunction: measureFunction,
              dimensionField: dimensionField,
              dataFilter: dataFilter,
              dateSorter: dateSorter,
              dataLimit: dataLimit,
              dataSortBy: dataSortBy
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },
      processData(){
        console.log('Requesting data...');
        this.getData(
          this.dataSource,
          this.measureField,
          this.measureFunction,
          this.dimensionFields.columns[this.currentDimension],
          this.dataFilter,
          this.dateSorter,
          this.dataLimit,
          this.dataSortBy
        ).then(response => {
          console.log('Response received...');

          //Set response to data and labels
          this.labels = response.data.map(dimension => dimension.dimension);
          this.datasets.data = response.data.map(measure => measure.measure);

          //Autodrill code
          if(this.autoDrill == 'true' && this.datasets.data.length == 1 && this.currentDimension < this.dimensionFields.columns.length-1){
            console.log('Skipping down a drill level from ' + this.dimensionFields.columns[this.currentDimension] + ' to ' + this.dimensionFields.columns[this.currentDimension+1]);
            this.currentDimension++;
            this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
            this.processData();
          }
          else{
            //Set render flag
            this.dataready = true;

            if(!this.chartBackgroundColor){
              this.chart.data.datasets[0].backgroundColor = this.startingColors.concat(randomColor({count: this.datasets.data.length, luminosity: 'dark', seed: 3}));
              this.chart.data.datasets[0].borderColor = this.startingColors.concat(randomColor({count: this.datasets.data.length, luminosity: 'dark', seed: 3}));
            }

            this.chart.data.labels = this.labels;
            this.chart.data.datasets[0].data = this.datasets.data;
            // console.log(this.chart);

            this.chart.update();
            this.chart.update();

          }
        })
        .catch(error => {
          console.log(error);
          this.dataready = false;
        });
      }
    },

    mounted () {
      if(this.dataBinding == 'dynamic'){
        EventBus.$on('sessionFilterChange', (message) => {
          if(message[0] == 'add'){
            if(this.dimensionFields.columns[this.currentDimension] == message[1]){
              if(this.dimensionFields.columns.length-1 > this.currentDimension){
                this.currentDimension++;
                this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
                console.log('Adding');
              }
            }
          }else if (message[0] == 'remove') {
            if(this.dimensionFields.columns[this.currentDimension] == message[1]){
              if(this.currentDimension != 0){
                this.currentDimension--;
                this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
              }
            }
          }else if (message[0] == 'clear') {
            //Nothing to do really
            //Check if you have dimension in list, if yes, are you below it? If yes, go up to it.
            var inMyList = this.dimensionFields.columns.indexOf(message[1][0]);
            console.log('Here? ' + inMyList);
            if(inMyList != -1 && this.currentDimension > inMyList){
              this.currentDimension = inMyList;
              this.datasets.dimension = this.dimensionFields.columns[this.currentDimension];
            }
          }

          this.processData();
        });

        EventBus.$on('globalFilterChange', (message) => {
          this.processData();
        });
      }

      this.labels = ['No Data'];

      //Set defaults
      this.datasets =
      {
        label: this.measureName,
        data: [0],
        dimension: this.dimensionFields.columns[this.currentDimension]
      }
      ;

      //Make initial data Request
      console.log('Initial data request...');
      this.processData();

      this.chart = new Chart(this.$refs.myChart, {
        type: this.chartType,
        data: {
          labels: ['No Data'],
          datasets: [{
            label: this.measureName,
            data: [0],
            backgroundColor: this.chartBackgroundColor,
            borderColor: this.chartBorderColor,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
                position: this.yAxesPosition,
                display: this.chartType != 'pie',
                ticks: {
                    beginAtZero: true
                }
            }]
          },
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: 'true',
            position: this.legendPosition
          }
        }
      });

    }
  }

  </script>
