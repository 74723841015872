<template>
    <div v-if="filtersApplied" class="spinner-grow text-danger" role="status" style="vertical-align:middle;width:1rem;height:1rem;">
    </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    data() {
      return {
        hasFilters: false
      }
    },

    computed: {
      filtersApplied: function(){
        return this.hasFilters === 'true';
      }
    },

    mounted() {
      this.processData();
      EventBus.$on('sessionFilterChange', (message) => {
        this.processData();
      });
    },

    methods: {
      async getData(){
        try{
          return await axios.get('/filter/check?mode=session');
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.getData().then(response => {
          this.hasFilters = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
  }
</script>
