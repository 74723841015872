<template>
<div v-if="currentSelections">
    <h5>Tags</h5>
    <div v-for="tag in currentSelections">
      <a v-on:click="removeSelection(tag)" class="btn btn-primary" style="margin-top:5px;color:white;">
        {{tag}} <i class="fas fa-fw fa-times-circle"></i>
      </a>
    </div>
</div>

</template>
<script>
  import { EventBus } from '../../event-bus.js';
    export default {
        data() {
          return {
            currentSelections: null
          }
        },

        mounted() {
          console.log('Selections Component mounted.');
          this.currentSelections = null
          this.processData();
          EventBus.$on('globalFilterChange', (message) => {
              this.processData();
          });
        },

        methods: {
          async getData(){
            try{
              return await axios.get('/filter/get?mode=tag')
            }
            catch (error) {
              console.log(error);
            }
          },
          processData(){
            console.log('Requesting current selctions...');
            this.getData().then(response => {
              console.log('Response received...');

              //Set response to data and labels
              console.log(response.data);
              this.currentSelections = response.data;
            })
            .catch(error => {
              console.log(error);
            });
          },

          removeSelection(dataValue){
            var POSTbody = {
                dataValue: dataValue,
                mode: 'tag'
            }
            axios({
                method: 'post',
                url: '/filter/remove',
                data: POSTbody
            })
            .then(function (response) {
                //console.log(response);
                //Sending refresh signal to other objects
                EventBus.$emit('globalFilterChange', ['remove', 'tag']);
            })
            .catch(function (error) {
                console.log(error);
            });
          }
        }
      }
  </script>
