import { EventBus } from '../../event-bus.js';
import {Pie} from 'vue-chartjs'
export default {
  extends: Pie,
  props: {
    tooltipPrefix: {type: String, default: ''},
    tooltipSuffix: {type: String, default: ''},
    chartDatasets: {
      type: Array | Object,
      required: false,
      default: null
    },
    chartLabels: {
      type: Array,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'left',
          labels: {
            fontSize: 10,
            boxWidth: 20
          }
        },
        responsive: true,
        scales: {
            xAxes: [ {
                gridLines: {
                  color: 'transparent',
                  zeroLineColor: 'transparent'
                },
                ticks: {
                  fontSize: 2,
                  fontColor: 'transparent'
                }
            } ],
            yAxes: [ {
                display:false,
                ticks: {
                    display: false,
                }
            } ]
        },
        title: {
            display: false,
        },
        elements: {
          line: {
              tension: 0.00001,
              borderWidth: 1
          },
          point: {
              radius: 4,
              hitRadius: 10,
              hoverRadius: 4
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return data.labels[tooltipItems.index] + ': ' + this._chart.options.tooltips.prefix + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + this._chart.options.tooltips.suffix;              
            }
          },
          prefix: null
        },
      })
    }
  },
  mounted () {
    this.callRender();
  },
  methods:{
    callRender(){
      this.chartDatasets.backgroundColor = randomColor({count: this.chartDatasets.data.length, luminosity: 'dark', seed: 2});
      this.chartOptions.tooltips.prefix = this.tooltipPrefix;
      this.chartOptions.tooltips.suffix = this.tooltipSuffix;
      this.renderChart({
        labels: this.chartLabels,
        datasets: [this.chartDatasets],
      }, this.chartOptions);
    }
  },
  watch: {
    'chartDatasets.data' : 'callRender'
  }
}
