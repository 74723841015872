
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */


//Charting
Vue.component('chart-js', require('./components/visual/ChartJs.vue').default);
Vue.component('chart-js-bare', require('./components/visual/ChartJsBare.vue').default);
Vue.component('chart-js-basic', require('./components/visual/ChartJsBasic.vue').default);
Vue.component('chart-js-native', require('./components/visual/ChartJsNative.vue').default);
Vue.component('chart-js-live', require('./components/visual/ChartJsLive.vue').default);
Vue.component('detail-table', require('./components/visual/DetailTable.vue').default);
Vue.component('detail-modal', require('./components/model/DetailModal.vue').default);
Vue.component('chartjs-multi-bar', require('./components/charts/native/chartjs-multi-bar.vue').default);

// Vue.component('summary-table', require('./components/visual/SummaryTable.vue').default);
Vue.component('kpi-card', require('./components/visual/KpiCard.vue').default);
Vue.component('rank-card', require('./components/visual/RankCard.vue').default);
Vue.component('kpi-spark', require('./components/visual/KpiSpark.vue').default);
Vue.component('kpi-basic', require('./components/visual/KpiBasic.vue').default);
Vue.component('kpi-fraction', require('./components/visual/KpiFraction.vue').default);


// ARLive Components
Vue.component('live-chartjs', require('./components/arlive/LiveChartjs.vue').default);
Vue.component('live-metric', require('./components/visual/LiveMetric.vue').default);
Vue.component('live-widget', require('./components/functional/LiveWidget.vue').default);
Vue.component('live-task-ticker', require('./components/visual/LiveTaskTicker.vue').default);

Vue.component('compliance-view', require('./components/visual/ComplianceView.vue').default);
Vue.component('compliance-health-check', require('./components/visual/ComplianceHealthCheck.vue').default);
Vue.component('ppm-compliance-aggregate', require('./components/visual/PPMComplianceAggregateTable.vue').default);
Vue.component('overdue-card', require('./components/visual/OverdueCard.vue').default);
//Vue.component('overdue-history', require('./components/visual/OverdueHistory.vue').default);
// Vue.component('progress-bar', require('./components/visual/ProgressBar.vue').default);

//Functional
Vue.component('multi-select', require('./components/functional/MultiSelect.vue').default);
// Vue.component('drag-select', require('./components/functional/DragSelect.vue').default);
// Vue.component('drop-down', require('./components/functional/DropDown.vue').default);

Vue.component('active-session-filters', require('./components/functional/ActiveSessionFilters.vue').default);
Vue.component('active-session-hint', require('./components/functional/ActiveSessionHint.vue').default);
Vue.component('active-session-reset', require('./components/functional/ActiveSessionReset.vue').default);

Vue.component('active-global-filters', require('./components/functional/ActiveGlobalFilters.vue').default);
Vue.component('active-global-hint', require('./components/functional/ActiveGlobalHint.vue').default);
Vue.component('active-global-reset', require('./components/functional/ActiveGlobalReset.vue').default);

Vue.component('active-tag-filters', require('./components/functional/ActiveTagFilters.vue').default);

// Vue.component('drag-global-filter', require('./components/functional/DragGlobalFilter.vue').default);
Vue.component('multi-select-global-filter', require('./components/functional/MultiSelectGlobalFilter.vue').default);

Vue.component('reset-all-filters', require('./components/functional/ResetAllFilters.vue').default);


Vue.component('select-contractor', require('./components/functional/SelectContractor.vue').default);

//Smart Table
import SmartTable from 'vuejs-smart-table'
Vue.use(SmartTable)


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});
