<template>
  <div class="mb-4">
    <div class="shadow h-100 py-2">
      <div class="row text-center">
        <div class="col text-xl" :class="ragColor ? ragColor : textColor">
          <div v-if="kpiReady" :class="kpiSize">{{animatedNumber}} {{this.bottomKpi ? '%' : ''}}</div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <div v-bind:class="labelColor" class="text-xs font-weight-bold text-uppercase mb-1">
            {{label}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  export default {
    props: {

      topKpi: {type: String, default: null},
      bottomKpi: {type: String, default: null},

      label: '',
      labelColor: {type: String, default: 'text-primary'},
      icon: '',

      textSize: {type: Number, default: 4},
      textColor: {type: String, default: 'text-primary'},

      ragConfig: {type: Object, default: null},

      taskType: {type: String, default: 'all'},


    },

    data () {
      return {
        topResult: 0,
        topReady: false,

        bottomResult: 0,
        bottomReady: false,

        kpiResultTween: 0,

        tweenedNumber: 0
      }
    },

    computed: {

      kpiSize: function(){
        return 'display-' + this.textSize;
      },

      ragColor: function(){
        if(this.ragConfig != null){
          if(this.ragConfig.direction == 'up'){
            if(this.kpiResult > this.ragConfig.thresholds[1]){
              return 'text-success';
            }
            else{
              if(this.kpiResult > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-danger'
              }
            }
          }
          else{
            if(this.kpiResult > this.ragConfig.thresholds[1]){
              return 'text-danger';
            }
            else{
              if(this.kpiResult > this.ragConfig.thresholds[0]){
                return 'text-amber';
              }
              else{
                return 'text-success'
              }
            }
          }

        }
      },

      kpiReady: function(){
        if(this.bottomKpi){
            return this.topReady && this.bottomReady;
        }
        else{
          if(!isNaN(this.topResult)){
            return true;
          }
        }
      },

      animatedNumber: function() {
        if(isNaN(this.$data.tweenedNumber)){
          //console.log(this.$data.tweenedNumber);
          return this.kpiResult;
        }
        else{
          return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },

      kpiResult: function(){
        if(this.topReady && this.bottomReady){
            return Math.round((this.topResult / this.bottomResult)*100,2);
        }
        else{
          return this.topResult;
        }
      }
    },

    watch: {
      kpiResult: function(newValue) {
        TweenLite.to(this.$data, 0.5, { tweenedNumber: newValue });
      }
    },

    mounted() {

      EventBus.$on('liveUpdate', (message) => {
        this.processData();
      });

      this.processData();
    },



    methods:{

      async getData(metric){
        try{
          return await axios.get('/getLiveMetric',{
            params: {
              metric: metric,
              taskType: this.taskType
            }
          })
        }
        catch (error) {
          console.log(error);
        }
      },

      processData(){
        this.getData(this.topKpi).then(response => {
          //Set response to data and labels
          this.topResult = response.data[0].measure;

          //Set render flag
          this.topReady = true;
        })
        .catch(error => {
          console.log(error);
          this.topReady = false;
        });

        if(this.bottomKpi){
          this.getData(this.topKpi).then(response => {
            //Set response to data and labels
            this.topResult = response.data[0].measure;

            //Set render flag
            this.topReady = true;
          })
          .catch(error => {
            console.log(error);
            this.topReady = false;
          });
        }

      }

      //End KPI Data Stuff
    }
  }
</script>
