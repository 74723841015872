<template>
  <div class="card text-white mb-4" v-bind:class="sparkBgStyle">
    <div class="card-body pb-0">
        <div class="row">
          <div class="col-md-12">
            <h4 class="mb-0">
              {{kpiPrefix}}<span v-if="kpiReady">{{animatedNumber}}</span>
            </h4>
            <p class="text-light">{{infoString}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="chart-wrapper px-0" >
              <component
                :is="sparkType"
                :chart-datasets="datasets"
                :chart-labels="labels"
                :height="80"
                :tooltip-prefix="tooltipPrefix"
                :tooltip-suffix="tooltipSuffix"
                v-if="dataReady">
              </component>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .spark-bg-1 {
    background: #20a8d8; }

  .spark-bg-2 {
    background: #63c2de; }

  .spark-bg-3 {
    background: #ffc107; }

  .spark-bg-4 {
    background: #f86c6b; }

  .spark-bg-5 {
    background: #4dbd74; }

  .spark-bg-red {
    background: red; }

  .spark-bg-mred {
    background: #8b0305; }

  .spark-bg-orange {
      background: #e07d0b; }

  .spark-bg-green {
      background: #07b318; }

</style>

<script>
import { EventBus } from '../../event-bus.js';
import SparkLine from '../charts/spark-line.js'
import SparkBar from '../charts/spark-bar.js'
import SparkPie from '../charts/spark-pie.js'
export default {

  components: {
    SparkBar,
    SparkLine,
    SparkPie
  },

  props: {
    dataSource: null,
    dataFilter: {type: Object},
    measureField: null,
    measureFunction: null,
    dimensionField: null,
    kpiSource: null,
    kpiField: null,
    kpiFunction: null,
    kpiPrefix: null,
    infoString: '',
    sparkType : {type: String, required: true},
    sparkBgStyle: null,
    dataBinding: {type: String, default: 'dynamic'},
    tooltipPrefix: '',
    tooltipSuffix: ''
  },

  data () {
    return {
      datasets: null,
      labels: null,
      dataReady: false,
      kpiResult: 0,
      kpiResultTween: 0,
      kpiReady: false,
      tweenedNumber: 0
    }
  },

  computed: {
    animatedNumber: function() {
      if(isNaN(this.$data.tweenedNumber)){
        //console.log(this.$data.tweenedNumber);
        return this.kpiResult;
      }
      else{
        return this.tweenedNumber.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },

  watch: {
    kpiResult: function(newValue) {
      TweenLite.to(this.$data, 0.5, { tweenedNumber: newValue });
    }
  },

  mounted () {

    //Set defaults
    this.labels = ['No Data'];
    this.datasets =
      {
        label: this.measureName,
        data: [0],
        dimension: this.dimensionField
      }
    ;

    //Make initial data Requests
    this.processKPIData();
    this.processChartData();

    if(this.dataBinding == 'dynamic'){
      EventBus.$on('sessionFilterChange', (message) => {
        this.processKPIData();
        this.processChartData();
      });
      EventBus.$on('globalFilterChange', (message) => {
        this.processKPIData();
        this.processChartData();
      });
    }

  },

  //-------------------------------------
  //Start of custom methods for component
  //-------------------------------------

  methods: {

  async getChartData(dataSource,
          measureField,
          measureFunction,
          dimensionField,
          dataFilter){
    try{
      console.log('Getting chart data...- kpi-spark-generic');
      return await axios.get('/getData',{
        params: {
          dataSource: dataSource,
          measureField: measureField,
          measureFunction: measureFunction,
          dimensionField: dimensionField,
          dataFilter: dataFilter
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  },
  processChartData(){
    console.log('Requesting data...');
    this.getChartData(
      this.dataSource,
      this.measureField,
      this.measureFunction,
      this.dimensionField,
      this.dataFilter
    ).then(response => {

      //Set response to data and labels
      this.labels = response.data.map(dimension => dimension.dimension);
      this.datasets.data = response.data.map(measure => measure.measure);

      //Set render flag
      this.dataReady = true;
    })
    .catch(error => {
      console.log(error);
      this.dataready = false;
    });
  },

    //Start KPI Data Stuff
    async getKPIData(
            kpiSource,
            kpiField,
            kpiFunction,
            dataFilter){
      try{
        return await axios.get('/getKPI',{
          params: {
            kpiSource: kpiSource,
            kpiField: kpiField,
            kpiFunction: kpiFunction,
            dataFilter: dataFilter
          }
        })
      }
      catch (error) {
        console.log(error);
      }
    },

    processKPIData(){
        this.getKPIData(
        this.kpiSource,
        this.kpiField,
        this.kpiFunction,
        this.dataFilter
      ).then(response => {

        //Set response to data and labels
        this.kpiResult = response.data[0].measure;

        //Set render flag
        this.kpiReady = true;
      })
      .catch(error => {
        console.log(error);
        this.kpiReady = false;
      });
    }
    //End KPI Data Stuff
  }
}
</script>
