import { EventBus } from '../../event-bus.js';
import {HorizontalBar} from 'vue-chartjs'
export default {
  extends: HorizontalBar,
  props: {
    yAxesMode: {type: String, default: 'normal'},
    tooltipPrefix: {type: String, default: ''},
    tooltipSuffix: {type: String, default: ''},
    chartColor: {default: null},
    selectionMode : null,
    chartDatasets: {
      type: Array | Object,
      required: false,
      default: null
    },
    chartLabels: {
      type: Array,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        animation: {
          duration: 1000
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontSize: 10
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [ {
            ticks: {
              fontSize: 10,
              beginAtZero: true,
              autoSkip: false,
              maxRotation: 60,
              minRotation: 0
            },
            gridLines: {
              display: true
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return this._chart.options.tooltips.prefix + tooltipItems.xLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + this._chart.options.tooltips.suffix;
            }
          },
          prefix: null
        },

        //Listen/respond to the following event types
        //events: ['onClick','onHover'],

        //(Re)define the onclick function for the chart
        'onClick': function(evt,item){
          if(item[0] != undefined && this.options.selectionMode == 'active'){
            //Use this to check which series is clicked in multi series datasets
            //console.log(this.getElementAtEvent(evt)[0]._datasetIndex);

            //will only be using single series datasets for now

            //POST the selection to the API to update the global filter
            console.log(this.data.datasets[0]);
            var POSTbody = {
                dataItem: this.data.datasets[0].dimension,
                dataValue: this.data.labels[item[0]._index],
                mode: 'session'
            }
            axios({
                method: 'post',
                url: '/filter/add',
                data: POSTbody
            })
            .then(function (response) {
                //Sending refresh signal to other objects
                console.log('Emitting - filter added for ' + this.data.datasets[0].dimension);
                EventBus.$emit('sessionFilterChange', ['add',this.data.datasets[0].dimension]);
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            });
          }
        }
      })
    }
  },
  methods: {
    callRender (){
      if(!this.chartColor){
        var fullSequence16 = [
          '#004b66'
          ,'#2f4b7c'
          ,'#665191'
          ,'#a05195'
          ,'#d45087'
          ,'#f95d6a'
          ,'#ff7c43'
          ,'#ffa600'
          ,'#6e00b8'
          ,'#ac0084'
          ,'#c0004f'
          ,'#bc0021'
          ,'#a73100'
          ,'#8a5300'
          ,'#696700'
          ,'#437500'

        ];
        var startingColors = fullSequence16;

        this.chartDatasets.backgroundColor = startingColors.concat(randomColor({count: this.chartDatasets.data.length, luminosity: 'dark', seed: 3}));
      } else{
        this.chartDatasets.backgroundColor = this.chartColor;
      }


      this.renderChart({
        labels: this.chartLabels,
        datasets: [this.chartDatasets],
      }, this.chartOptions);
    }
  },
  watch: {
    //'chartLabels' : 'callRender',
    'chartDatasets.data' : 'callRender'
  },
  mounted () {
    this.chartOptions.selectionMode = this.selectionMode;
    this.chartDatasets.borderWidth= 2;
    if(this.yAxesMode == 'percentage'){
      this.chartOptions.scales.xAxes[0].ticks.min = 0;
      this.chartOptions.scales.xAxes[0].ticks.max = 100;
      this.chartOptions.scales.xAxes[0].ticks.stepSize = 10;
    }
    if(this.yAxesMode == 'money'){
      this.chartOptions.scales.xAxes[0].ticks.callback = function(value,index,values){
        return '£' + (value/1000) + 'k';
      };
    }
    this.chartOptions.tooltips.prefix = this.tooltipPrefix;
    this.chartOptions.tooltips.suffix = this.tooltipSuffix;
    this.callRender();
  }
}
